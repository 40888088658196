exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-best-examples-of-flutter-mobile-apps-js": () => import("./../../../src/pages/blog/best-examples-of-flutter-mobile-apps.js" /* webpackChunkName: "component---src-pages-blog-best-examples-of-flutter-mobile-apps-js" */),
  "component---src-pages-blog-cost-to-develop-flutter-app-js": () => import("./../../../src/pages/blog/cost-to-develop-flutter-app.js" /* webpackChunkName: "component---src-pages-blog-cost-to-develop-flutter-app-js" */),
  "component---src-pages-blog-custom-software-development-costs-js": () => import("./../../../src/pages/blog/custom-software-development-costs.js" /* webpackChunkName: "component---src-pages-blog-custom-software-development-costs-js" */),
  "component---src-pages-blog-flutter-vs-going-native-mobile-development-js": () => import("./../../../src/pages/blog/flutter-vs-going-native-mobile-development.js" /* webpackChunkName: "component---src-pages-blog-flutter-vs-going-native-mobile-development-js" */),
  "component---src-pages-blog-flutter-vs-react-native-performance-comparison-js": () => import("./../../../src/pages/blog/flutter-vs-react-native-performance-comparison.js" /* webpackChunkName: "component---src-pages-blog-flutter-vs-react-native-performance-comparison-js" */),
  "component---src-pages-blog-how-to-choose-a-flutter-development-company-js": () => import("./../../../src/pages/blog/how-to-choose-a-flutter-development-company.js" /* webpackChunkName: "component---src-pages-blog-how-to-choose-a-flutter-development-company-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-native-vs-hybrid-vs-pwa-mobile-apps-which-should-you-choose-js": () => import("./../../../src/pages/blog/native-vs-hybrid-vs-pwa-mobile-apps-which-should-you-choose.js" /* webpackChunkName: "component---src-pages-blog-native-vs-hybrid-vs-pwa-mobile-apps-which-should-you-choose-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */)
}

